import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7a827f87 = () => interopDefault(import('../pages/flowering.vue' /* webpackChunkName: "pages/flowering" */))
const _322abae7 = () => interopDefault(import('../pages/planting.vue' /* webpackChunkName: "pages/planting" */))
const _13444b5b = () => interopDefault(import('../pages/planting2.vue' /* webpackChunkName: "pages/planting2" */))
const _66a617f9 = () => interopDefault(import('../pages/project.vue' /* webpackChunkName: "pages/project" */))
const _2f4ac9c4 = () => interopDefault(import('../pages/science.vue' /* webpackChunkName: "pages/science" */))
const _c3e94d52 = () => interopDefault(import('../pages/simulation.vue' /* webpackChunkName: "pages/simulation" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/flowering",
    component: _7a827f87,
    name: "flowering"
  }, {
    path: "/planting",
    component: _322abae7,
    name: "planting"
  }, {
    path: "/planting2",
    component: _13444b5b,
    name: "planting2"
  }, {
    path: "/project",
    component: _66a617f9,
    name: "project"
  }, {
    path: "/science",
    component: _2f4ac9c4,
    name: "science"
  }, {
    path: "/simulation",
    component: _c3e94d52,
    name: "simulation"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
